<template>
  <div id="bacteria">
    <el-dialog
      :title="bacteriaFormTitle"
      width="1000px"
      :visible.sync="bacteriaDialogVisible"
      :close-on-click-modal="false"
      @close="bacteriaDialogClose"
    >
      <el-form
        ref="bacteriaFormRef"
        :model="bacteriaForm"
        :rules="bacteriaFormRules"
        label-position="right"
        label-width="120px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="菌种名称" prop="name">
              <el-input v-model="bacteriaForm.name" placeholder="请输入菌种名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="菌种编号" prop="no">
              <el-input v-model="bacteriaForm.no" placeholder="请输入菌种编号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="菌种代数" prop="generation">
              <el-input v-model="bacteriaForm.generation" placeholder="请输入菌种代数" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="数量" prop="quantity">
              <el-input v-model="bacteriaForm.quantity" placeholder="请输入数量" clearable />
            </el-form-item>
          </el-col>
           <el-col :span="8">
            <el-form-item label="加水量" prop="waterVolume">
              <el-input v-model="bacteriaForm.waterVolume" placeholder="请输入加水量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="来源" prop="source">
              <el-input v-model="bacteriaForm.source" placeholder="请输入来源" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="购进日期" prop="purchaseDate">
              <el-date-picker v-model="bacteriaForm.purchaseDate" placeholder="请选择日期" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="验收人" prop="acceptor">
              <el-input v-model="bacteriaForm.acceptor" placeholder="请输入验收人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="验收日期" prop="acceptanceDate">
              <el-date-picker v-model="bacteriaForm.acceptanceDate" placeholder="请选择日期" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="bacteriaDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="bacteriaFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="设备编号">
        <el-input v-model="searchForm.deviceNo" placeholder="请输入设备编号" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="bacteriaPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="name" label="菌种名称" />
      <el-table-column prop="no" label="菌种编号" />
      <el-table-column prop="generation" label="菌种代数" />
      <el-table-column prop="quantity" label="数量" />
      <el-table-column prop="source" label="来源" />
      <el-table-column prop="purchaseDate" label="购进日期" />
      <el-table-column prop="acceptor" label="验收人" />
      <el-table-column prop="acceptanceDate" label="验收日期" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="bacteriaPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addBacteria, deleteBacteria, updateBacteria, selectBacteriaInfo, selectBacteriaList } from '@/api/quality/bacteria'

export default {
  data () {
    return {
      bacteriaDialogVisible: false,
      bacteriaFormTitle: '',
      bacteriaForm: {
        id: '',
        name: '',
        no: '',
        generation: '',
        quantity: '',
        source: '',
        purchaseDate: '',
        acceptor: '',
        acceptanceDate: ''
      },
      bacteriaFormRules: {
        name: [{ required: true, message: '菌种名称不能为空', trigger: ['blur', 'change']}]
      },
      bacteriaPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        deviceNo: ''
      }
    }
  },
  created () {
    selectBacteriaList(this.searchForm).then(res => {
      this.bacteriaPage = res
    })
  },
  methods: {
    bacteriaDialogClose () {
      this.$refs.bacteriaFormRef.resetFields()
      this.bacteriaForm.id = ''
    },
    bacteriaFormSubmit () {
      if (this.bacteriaFormTitle === '菌种外购信息') {
        this.bacteriaDialogVisible = false
        return
      }
      this.$refs.bacteriaFormRef.validate(async valid => {
        if (valid) {
          if (this.bacteriaFormTitle === '新增菌种外购信息') {
            await addBacteria(this.bacteriaForm)
          } else if (this.bacteriaFormTitle === '修改菌种外购信息') {
            await updateBacteria(this.bacteriaForm)
          }
          this.bacteriaPage = await selectBacteriaList(this.searchForm)
          this.bacteriaDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.bacteriaFormTitle = '新增菌种外购信息'
      this.bacteriaDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteBacteria(row.id)
        if (this.bacteriaPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.bacteriaPage = await selectBacteriaList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.bacteriaFormTitle = '修改菌种外购信息'
      this.bacteriaDialogVisible = true
      this.selectBacteriaById(row.id)
    },
    handleInfo (index, row) {
      this.bacteriaFormTitle = '培养菌种外购信息'
      this.bacteriaDialogVisible = true
      this.selectBacteriaById(row.id)
    },
    selectBacteriaById (id) {
      selectBacteriaInfo(id).then(res => {
        this.bacteriaForm.id = res.id
        this.bacteriaForm.name = res.name
        this.bacteriaForm.no = res.no
        this.bacteriaForm.generation = res.generation
        this.bacteriaForm.quantity = res.quantity
        this.bacteriaForm.source = res.source
        this.bacteriaForm.purchaseDate = res.purchaseDate
        this.bacteriaForm.acceptor = res.acceptor
        this.bacteriaForm.acceptanceDate = res.acceptanceDate
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectBacteriaList(this.searchForm).then(res => {
        this.bacteriaPage= res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectBacteriaList(this.searchForm).then(res => {
        this.bacteriaPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectBacteriaList(this.searchForm).then(res => {
        this.bacteriaPage = res
      })
    }
  }
}
</script>

<style>
</style>
